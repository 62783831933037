@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
display: none;
}

body{
    @apply font-[Prod-normal] bg-[#040214];
}

.homebutton {
    @apply rounded-full bg-white px-7 font-normal text-black lg:text-lg;
}

.evoxhighlight{
    @apply text-[#AFBEF3]
}

.middleshadow{
    box-shadow: 0px 4px 20px 20px rgb(255 255 255 / 0.05);
}

.active{
    @apply text-white underline underline-offset-8;
}

.buttonSelect {
    @apply w-fit text-center rounded-full border-4 border-[#1e3150] uppercase px-6 py-3 duration-100 ease-linear hover:bg-[#7e76dd];
}

.fadeEffect {
    @apply !bg-clip-text text-center font-[prod-bold] text-[156px] text-transparent [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]
}
